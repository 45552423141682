<template>
  <div class="container">


    <div class="education-module-form mt-4">
      <h4 style="text-align: center">Модули</h4>


      <!-- Delete Education Module Modal -->
      <div class="modal fade" id="deleteEducationModuleModal" tabindex="-1"
           aria-labelledby="deleteEducationModuleModalLabel"
           aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteEducationFieldModalLabel">Удаление модуля</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="col-md-12 mt-4">
                <p>Вы точно хотите удалить этот модуль?</p>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                      @click="deleteEducationModule(deleteEducationModuleId)">
                Удалить
              </button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Отменить
              </button>

            </div>
          </div>
        </div>
      </div>
      <!-- End Modal -->

      <div class="education-module-table">
        <div class="row mt-4">

          <div class="col-md-2">
            №
          </div>
          <div class="col-md-4">
            Название
          </div>
          <div class="col-md-4">
            Название группы
          </div>
          <div class="col-md-1">
            Редактировать
          </div>
          <div class="col-md-1">
            Удалить
          </div>

        </div>
        <hr>
        <div class="row" v-for="(item,index) in educationmodule_form.educationModuleInfos" :key="index">

          <div class="col-md-2">
            {{ index+1 }}
          </div>
          <div class="col-md-4">

            <input class="form-control"
                   placeholder="Название" :value="item.module"
                   @input="changeEducationModuleData(item, 'module', $event)"
                   :disabled="typeof item.edit_status=== 'undefined'" />

          </div>

          <div class="col-md-4">

            <input class="form-control"
                   placeholder="Название группы" :value="item.name"
                   @input="changeEducationModuleData(item, 'name', $event)"
                   :disabled="typeof item.edit_status=== 'undefined'" />

          </div>
          <div class="col-md-1">
            <button v-if="typeof item.edit_status=== 'undefined'" type="button" class="btn btn-warning"
                    @click="editEducationModule(index)">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>

            <button v-if="item.edit_status == 1" type="button" class="btn btn-success"
                    @click="updateEducationModule(item.id, index)">
              <i class="fas fa-check" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-md-1">
            <button type="button" class="btn btn-danger" data-bs-toggle="modal"
                    data-bs-target="#deleteEducationModuleModal"
                    @click="openDeleteEducationModuleModal(item.id)">
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </button>
          </div>

          <hr>

        </div>
      </div>


      <div class="form-row" v-for="(newEducationModuleInfo, index) in educationmodule_form.newEducationModuleInfos"
           :key="index">
        <div class="education-field-remove-item__button d-flex flex-row-reverse">
          <i class="fa fa-times" @click="deleteRowEducationModuleInfo(index)"></i>
        </div>


        <div class="form-group row mt-4">
          <label for="education_module" class="col-md-3 col-form-label">Название</label>
          <div class="col-md-9">
            <input id="education_module" type="text" class="form-control"
                   @input="changeNewEducationModuleData(newEducationModuleInfo, 'module', $event)">
          </div>

        </div>
        <div class="form-group row mt-4">
          <label for="education_module_name" class="col-md-3 col-form-label">Название группы</label>
          <div class="col-md-9">
            <input id="education_module_name" type="text" class="form-control"
                   @input="changeNewEducationModuleData(newEducationModuleInfo, 'name', $event)">
          </div>

        </div>
        <div class="form-group row mt-4">
          <div class="col-md-12 text-center">
            <button class="btn btn-pill text-white btn-block btn-primary" v-on:click="submitEducationModule">
              Сохранить
            </button>
          </div>
        </div>


        <hr>

      </div>
      <div class="form-group">
        <button @click="addRowEducationModuleInfo" type="button" class="btn btn-secondary"><i
            class="fas fa-plus-circle"></i>
          Добавить
        </button>
      </div>
    </div>


  </div>
</template>

<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "EducationModule",


  data() {
    return {
      deleteEducationModuleId: ''
    }
  },
  computed: {
    ...mapState('educationmodule', ['educationmodule_form']),
  },

  methods: {
    ...mapActions('educationmodule', ['POST_EDUCATION_MODULE_DATA', 'PUT_EDUCATION_MODULE_DATA', 'GET_EDUCATION_MODULE_DATA', 'DELETE_EDUCATION_MODULE_DATA']),
    ...mapMutations('educationmodule', ['UPDATE_EDUCATION_MODULE_DATA', 'UPDATE_NEW_EDUCATION_MODULE_DATA',
      'DELETE_ROW_EDUCATION_MODULE_INFO', 'ADD_ROW_EDUCATION_MODULE_INFO', 'CHANGE_EDIT_STATUS_EDUCATION_MODULE_INFO']),


    async deleteEducationModule(id) {
      await this.DELETE_EDUCATION_MODULE_DATA(id);
      this.$message({title: 'Удаление', text: 'Успешное удаление'})
      await this.GET_EDUCATION_MODULE_DATA();
    },
    async editEducationModule(index) {
      this.CHANGE_EDIT_STATUS_EDUCATION_MODULE_INFO(index)

    },
    async openDeleteEducationModuleModal(id) {
      this.deleteEducationModuleId = id;
      console.log(this.deleteEducationModuleId, "DELETE FILE ID")
    },
    async updateEducationModule(id, index) {
      delete this.educationmodule_form.educationModuleInfos[index].edit_status;
      await this.PUT_EDUCATION_MODULE_DATA(id)
    },

    deleteRowEducationModuleInfo(index) {
      this.DELETE_ROW_EDUCATION_MODULE_INFO(index);
    },
    addRowEducationModuleInfo() {
      this.ADD_ROW_EDUCATION_MODULE_INFO();
    },
    submitEducationModule() {

      this.POST_EDUCATION_MODULE_DATA().then(res => {
        if (res.success == true) {
          this.GET_EDUCATION_MODULE_DATA();
          this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$error({title: 'Добавление', text: errorText})
        }
      })
    },
    changeEducationModuleData(item, property, e, val = 'value') {
      const value = e.target[val]
      this.UPDATE_EDUCATION_MODULE_DATA({item, property, value})
    },
    changeNewEducationModuleData(item, property, e, val = 'value') {
      const value = e.target[val]
      this.UPDATE_NEW_EDUCATION_MODULE_DATA({item, property, value})
    },
  },
  created() {
    this.GET_EDUCATION_MODULE_DATA();
  }
}
</script>

<style scoped>

</style>